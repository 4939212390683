<!--
* @description:
* @fileName shopManage.vue
* @author hvv
* @date 2022/01/13 15:21:21
!-->
<template>
  <div>
    <vab-query-form style="padding-top: 20px">
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item label="客户姓名">
            <el-input v-model="queryForm.name" placeholder="请输入客户姓名" />
          </el-form-item>
          <!-- <el-form-item
            label="客户手机号"
            style="margin: 0 10px 10px 10px !important"
          >
            <el-input
              v-model="queryForm.phone"
              placeholder="请输入客户手机号"
            />
          </el-form-item> -->

          <el-form-item label="客户身份">
            <el-select
              v-model="queryForm.level"
              class="m-2"
              placeholder="请选择客户身份"
            >
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="权益名称">
            <el-select
              v-model="queryForm.couponSource"
              class="m-2"
              placeholder="请选择权益名称"
            >
              <el-option
                v-for="item in typeList"
                :key="item.couponType"
                :label="item.couponName"
                :value="item.couponType"
              />
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
            <!-- <el-button :icon="Search" type="primary" @click="queryData">
              导出
            </el-button> -->
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <div style="padding-left: 10px">
      <el-table v-loading="listLoading" border :data="list">
        <el-table-column label="客户姓名" prop="name" show-overflow-tooltip />
        <el-table-column
          label="客户手机号"
          prop="phone"
          show-overflow-tooltip
        />
        <el-table-column label="客户身份">
          <template #default="{ row }">
            <span v-if="row.level == 'DIAMOND'">钻石</span>
            <span v-else>翡翠</span>
          </template>
        </el-table-column>
        <el-table-column
          label="发券权益内容"
          prop="couponName"
          show-overflow-tooltip
        />
        <el-table-column label="发送时间" prop="reviewTime">
          <template #default="{ row }">
            <span>{{ handleDate(row.sendTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="发送状态" prop="status">
          <template #default="{ row }">
            <el-tag v-if="row.status == 1" type="success">发送成功</el-tag>
            <el-tag v-if="row.status == 0" type="danger">发送失败</el-tag>
          </template>
        </el-table-column>
        <template #empty>
          <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
          <el-empty class="vab-data-empty" description="暂无数据" />
        </template>
      </el-table>
      <el-pagination
        background
        :current-page="queryForm.pageNumber"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <edit ref="editRef" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import {
    defineComponent,
    defineAsyncComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'

  import { Delete, Plus, Search } from '@element-plus/icons'
  import { getCouponList, getCouponType } from '@/api/passApply'
  import { parseTime } from '@/utils/index'
  export default defineComponent({
    name: 'TicketList',
    components: {
      Edit: defineAsyncComponent(() => import('./components/editHost')),
    },
    setup() {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        queryForm: {
          pageNum: 1,
          pageSize: 20,
          phone: '',
          level: '',
          couponSource: 'ALL',
        },
        total: 0,
        shopList: [],
        listLoading: false,
        list: [],
        layout: 'prev, pager, next',
        editRef: null,
        typeOptions: [
          {
            label: '全部',
            value: 'ALL',
          },
          {
            label: '翡翠',
            value: 'EMERALD',
          },
          {
            label: '钻石',
            value: 'DIAMOND',
          },
        ],
        typeList: [],
      })

      const handleEdit = (row) => {
        state.editRef.showEdit(row)
      }
      const queryData = () => {
        fetchData()
      }
      const handleExport = async () => {
        state.listLoading = true
        state.queryForm.pageSize = 99999
        try {
          let resp = await couponListExport(state.queryForm)
          let url = resp?.data?.fileUrl
          let a = document.createElement('a')
          let lastIndexOf = url.toString().lastIndexOf('/') + 1
          a.href = url
          let fileName = url.slice(lastIndexOf) || '文件模板.xlsx'
          a.download = fileName
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          a.remove()
          setTimeout(() => {
            proxy.$baseMessage(`导出成功`, 'success', 'vab-hey-message-success')
          }, 300)
        } catch (error) {
          console.log('导出失败', error)
          proxy.$baseMessage(`导出失败`, 'error', 'vab-hey-message-error')
        } finally {
          state.listLoading = false
        }
      }
      const initSearchType = async () => {
        const { data } = await getCouponType()
        state.typeList = data
      }

      const fetchData = async () => {
        state.listLoading = true
        const { data } = await getCouponList(state.queryForm)
        console.log(data, '1123123')
        state.list = data.data
        state.total = data.total
        state.listLoading = false
      }

      const handleSizeChange = (val) => {
        state.queryForm.pageSize = val
        queryData()
      }
      const handleCurrentChange = (val) => {
        state.queryForm.pageNum = val
        queryData()
      }

      const handleDate = (val) => {
        return parseTime(val)
      }

      const handleClick = (val) => {
        console.log(val)
        queryData()
      }

      onMounted(() => {
        queryData()
        initSearchType()
      })

      return {
        ...toRefs(state),
        handleEdit,
        queryData,
        fetchData,
        handleSizeChange,
        handleCurrentChange,
        handleDate,
        handleClick,
        handleExport,
        Delete,
        Search,
        Plus,
      }
    },
  })
</script>
<style lang="scss" scoped></style>
